import React from 'react';
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby";
import queryString from 'query-string';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Layout from '../components/layout';
import bg from "../images/blur-bg2.jpg";
import LeftSidebar from '../components/LeftSidebar/leftsidebar.js';
import '../common/src/assets/css/style';
import { Container, Row, Col, ProgressBar, Modal, Button, FormControl } from 'react-bootstrap';
import '../bootstrap/dist/css/bootstrap.min.css';
import mailbox from "../images/mailbox.svg";
import lock from "../images/lock.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import { graphql, StaticQuery } from 'gatsby';
import { TextareaAutosize } from '@material-ui/core';


class FinalPage extends React.Component {
  constructor(props){
    super(props)
    this.state = { 
      pageNumber: 10, 
      progresBar: 100, 
      openAlert: false, 
      show:true, 
      closePop:false,
      closeButton: true,
      names:'',
      email:'',
      phone:'',
      checkTF:false,
      errornames: '',
      erroremail:'',
      errorphone:'',
      formData: {
        plaats: '',
        werkzaamheden: [],
        rechtsvorm: '',
        jaaromzet: '',
        levert_u_administratie: '',
        naam_bedrijf: '',
        meer_informatie: ''
      } 
    };

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeTele = this.handleChangeTele.bind(this);
  }

  componentDidMount() {
    if(window.history.state) {
      if (window.history.state.formData) {
        const formDataVal = {
          plaats: window.history.state.formData.plaats,
          werkzaamheden: window.history.state.formData.werkzaamheden,
          rechtsvorm: window.history.state.formData.rechtsvorm,
          jaaromzet: window.history.state.formData.jaaromzet,
          levert_u_administratie: window.history.state.formData.levert_u_administratie,
          naam_bedrijf: window.history.state.formData.naam_bedrijf,
          meer_informatie: window.history.state.formData.meer_informatie
        };

        this.setState({ formData: formDataVal })
      }else {
        navigate('/');
      }
    }else {
      navigate('/');
    }
  }
  
  goToPrevPage = () => {
    navigate('/',
      {
        state: {
          formData: this.state.formData,
          pageNumber: 8
        },
      }
    );
  }

  handleChangeName = (e) => {
    if(e.target.value == ''){
      this.setState({ names: e.target.value, errornames: 'Invoer is nodig' });
    }
    else{
      this.setState({ names: e.target.value, errornames: '' });
    } 
  }

  handleChangeEmail = (e) => {
    var emailpattern = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4};?)+$/;
    if(emailpattern.test(e.target.value)){
      this.setState({ email: e.target.value, erroremail: '', checkTF: true });
    }
    else{
      this.setState({ email: e.target.value, erroremail: 'Invoer is nodig', checkTF: false });
    }
  }

  handleChangeTele = (e) => {
    var phonepattern = /^\d{10}$/;
    if(e.target.value.match(phonepattern)) {
      this.setState({ phone: e.target.value, errorphone: '' });
    }else{ 
      this.setState({ phone: e.target.value, errorphone: 'Invoer is nodig' });
    }
  }

  handleClose=()=>{
    this.setState({
      show:true,
      openAlert:true,
      closePop:true,
      closeButton: false
    })
  }

  handleCloseAll=()=>{
    this.setState({
      show:false,
      openAlert:false,
      closePop:false,
      closeButton: true
    })
  }

  warningClose=()=>{
    this.setState({
      openAlert:false,
      closeButton: true
    })
  }

  openModal=()=>{
    this.setState({
      show:true
    })
  }

  render() {
    const { progresBar, formData } = this.state;
    const closeContent = this.props.data.contentfulClosePopupContent;

    const data = this.props.data.contentfulStartBoekhouderForm;
    
    return (
      <Layout>
        <div className="main-content blue-bg" style={{ backgroundImage: `url(${bg})` }}>
          <Container>
            <div className="modeldesign">  
              <Row>

                <Col xs={12} md={4}>
                  <LeftSidebar></LeftSidebar>
                </Col>
                <Col xs={12} md={8}>
                  <Modal.Header closeButton={this.state.closeButton} onClick={this.handleClose}>
                  </Modal.Header>
                  <div className="form-wrapper">
                    {this.state.openAlert &&
                         <div className="modal-close">
                            <div>
                              <div className="mainFormContent">
                              <Modal.Header closeButton={false}>
         
                               </Modal.Header>
                                 <h2>{closeContent.title}</h2>
                                <div className="mt-5 text-center closeContent">
                                  {documentToReactComponents(JSON.parse(closeContent.body.raw))}
                                </div>                            
                                 <div className="mt-5 d-flex justify-content-center">
                                  <div className="m-2">
                                  <Link to={closeContent.cancelButtonUrl}>
                                    <Button className="btn orange-btn border-btn">{closeContent.continueBtn}</Button></Link>
                                  </div>
                                  <div className="m-2">
                                    <Button className="btn orange-btn" onClick={this.warningClose}>{closeContent.cancelBtn}</Button>
                                    <p className="button-text">{closeContent.smallText1}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="form-bottom form-bottom-no-border">
                                <div className="lock-icon-text d-flex align-items-center closeContent">
                                  <img src={lock} alt="lock-icon" />
                                  {documentToReactComponents(JSON.parse(closeContent.privacyStatement.raw))}
                                </div> 
                              </div>
                            </div>
                          </div>

                    } 

                    {!this.state.openAlert &&
                    <>
                      <div className="progressBar">
                        <ProgressBar now={progresBar} />
                      </div>
                    
                      <form method="post" action="/success" className="customForm" data-netlify="true" data-netlify-honeypot="bot-field" name="StartFormSubmit">
                        <input type="hidden" name="bot-field" />
                        <input type="hidden" name="form-name" value="StartFormSubmit" />
                        <input type="text" className="hiddenfield" name="plaats" value={formData.plaats} />
                        <input type="text" className="hiddenfield" name="werkzaamheden" value={formData.werkzaamheden.join(",")} />
                        <input type="text" className="hiddenfield" name="rechtsvorm" value={formData.rechtsvorm} />
                        <input type="text" className="hiddenfield" name="jaaromzet" value={formData.jaaromzet} />
                        <input type="text" className="hiddenfield" name="levert_u_administratie" value={formData.levert_u_administratie} />
                        <input type="text" className="hiddenfield" name="naam_bedrijf" value={formData.naam_bedrijf} />
                        <input type="text" className="hiddenfield" name="meer_informatie" value={formData.meer_informatie} />
                        <div className="mainFormContent">
                          <h2>{data.step9Heading}</h2>
                          <Row className="mt-5">
                            <Col xs={12} md={6}>
                              <p>{data.step9SubHeading}</p>
                              <div style={{ color: 'red' }}>{this.state.errornames}</div>
                              <div className="mb-5 position-relative">
                                <em>{data.step9NameFieldLabel}</em>
                                  <FormControl
                                    placeholder={data.step9NameFieldPlaceholder}
                                    aria-label="Naam"
                                    aria-describedby="basic-addon1"
                                    onChange={this.handleChangeName}
                                    value={this.state.names}
                                    name="names"
                                    required
                                  />
                                  <FontAwesomeIcon icon={faCheckCircle} className="validInput"/>
                              </div>
                                      
                              <div className="mb-5 position-relative">
                                <div style={{ color: 'red' }}>{this.state.erroremail}</div>
                                <em>{data.step9EmailFieldLabel}</em>
                                <FormControl
                                  placeholder={data.step9EmailFieldPlaceholder}
                                  aria-label="E-mailadres"
                                  aria-describedby="basic-addon2"
                                  onChange={this.handleChangeEmail}
                                  value={this.state.email}
                                  name="email"
                                  required
                                />
                                <FontAwesomeIcon icon={faCheckCircle} className="validInput"/>
                                <span className="input-text">{data.step9EmailFieldHelpText}</span>
                              </div>
                              
                              <div style={{ color: 'red' }}>{this.state.error3}</div>
                              <div className={this.state.checkTF ? 'mb-3 position-relative' : 'mb-3 position-relative d-none'}  >
                                <em>{data.step9PhoneFieldLabel}</em>
                                  <FormControl
                                    placeholder={data.step9PhoneFieldPlaceholder}
                                    aria-label="Telefoonnummer"
                                    aria-describedby="basic-addon2"
                                    onChange={this.handleChangeTele}
                                    value={this.state.phone}
                                    name="phone"
                                    required
                                  />
                                  <span className="input-text">{data.step9PhoneFieldHelpText}</span>
                              </div>
                              </Col>
                              <Col xs={12} md={6} className="mailbox">
                                <div className="text-center">
                                  <img src={mailbox} alt="mailbox" className="mailbox-img" />
                                </div>
                                <div className="d-flex align-items-center justify-content-end mt-5 lock-icon-text">
                                  <img src={lock} alt="lock-icon"  />
                                  {documentToReactComponents(JSON.parse(data.step9PrivacyStatement.raw))}
                                </div>
                              </Col>
                            </Row>
                        </div>
                        <div className="form-bottom">
                          <Button className="btn orange-btn transparent-btn float-left" onClick={() => this.goToPrevPage()}><FontAwesomeIcon icon={faArrowLeft} className="mr-1"/>Vorige</Button>
                          <div className="float-right">
                            <button className="btn orange-btn" type="submit">{data.step9NextButtonText}</button>
                            <p>Gratis en vrijblijvend, je zit nergens aan vast.</p>
                          </div>
                        </div>
                      </form>
                    </>
                    }


                  </div>


                </Col>
              
            </Row>
            </div>
          </Container>
        </div>

      </Layout>
    )
  }

}



FinalPage.propTypes = {
  data: PropTypes.object,
}


export default function data1(props) {
  return (
    <StaticQuery
      query={graphql`
      query {
          contentfulClosePopupContent {
            body {
              raw
            }
            cancelBtn
            cancelButtonUrl
            continueBtn
            title
            privacyStatement {
              raw
            }
            smallText1
          }
          contentfulStartBoekhouderForm {
            step9EmailFieldLabel
            step9EmailFieldPlaceholder
            step9Heading
            step9NameFieldLabel
            step9NameFieldPlaceholder
            step9NextButtonText
            step9PhoneFieldLabel
            step9PhoneFieldPlaceholder
            step9SubHeading
            finalRedirectUrl
            step1Heading
            step2Heading
            step3Heading
            step4Heading
            step5Heading
            step6Heading
            step7Heading
            step8Heading
            step9PrivacyStatement {
              raw
            }
            step9EmailFieldHelpText
            step9PhoneFieldHelpText
          }
        }
      `}
      render={data => <FinalPage data={data} {...props} />}
    />
  )
}